<template>
	<div class="c-page pt-10">
		<div class="c-page__inner row px-1" v-if="page">
			<div class="c-page__title text-primary section-title w-100">
				{{ page.title }}
			</div>

			<div v-if="page.sections && page.sections.length" class="w-100">
				<div
					class="component"
					:class="`--${section.type}`"
					v-for="section in page.sections"
					:key="section.id"
				>
					<div
						class="py-2"
						v-if="
							section.visible && (section.content || section.slug)
						"
					>
						<BasicText
							v-if="section.type === 'basic-text'"
							:content="section.content"
						/>
						<BasicButtons
							v-if="section.type === 'basic-buttons'"
							:content="section.content"
						/>
						<ImageGrid
							v-if="section.type === 'image-grid'"
							:content="section.content"
						/>
						<Accordion
							v-if="section.type === 'accordion'"
							:content="section.content"
						/>
						<Promo-Rotator
							v-if="section.type === 'promo-rotator'"
							:content="section.content"
						/>
						<Videos
							v-if="section.type === 'videos'"
							:slug="section.slug"
						/>
						<Photos
							v-if="section.type === 'photos'"
							:slug="section.slug"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="c-page__social mt-3 py-2">
			<div
				class="row px-1 h-100 flex-column medium-flex-row align-items-center justify-content-center medium-justify-content-end relative"
			>
				<div class="c-page__social-logo">
					<svg
						id="Layer_1"
						data-name="Layer 1"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 180 180"
						height="74"
						width="74"
					>
						<path
							class="st0"
							fill="#FFB71B"
							d="M83.166 96.439v-4.602l7.507.002V74.038H57.859v17.801h7.506v4.6h17.801z"
						/>
						<path
							class="st0"
							fill="#FFB71B"
							d="M155.905 91.837v-17.8H123.09v17.8h7.51v51.9c0 1.326-.154 1.825-.751 2.422l-3.667 3.665c-.612.614-1.065.755-2.425.755H90.01c-1.358 0-1.812-.141-2.429-.758l-3.66-3.659c-.6-.6-.755-1.1-.755-2.425v-26.709h8.044c5.443 0 9.039 0 13.3-4.255l7.079-7.08c4.267-4.26 4.266-7.782 4.266-13.114V73.292c0-5.329 0-8.849-4.251-13.093l-2.448-2.449 2.433-2.435c4.267-4.259 4.266-7.781 4.266-13.114v-7.943c0-5.329 0-8.85-4.251-13.094l-7.104-7.109c-4.247-4.241-7.842-4.241-13.286-4.241H24.1v17.8h8.579v71.613H24.1v17.8h41.266v26.713c0 5.443 0 9.038 4.255 13.3l7.081 7.079c4.257 4.265 7.7 4.265 12.922 4.265h34.521c5.214 0 8.659 0 12.9-4.25l7.11-7.107c4.242-4.248 4.242-7.843 4.242-13.286V95.856l-8.9-4.017zM91.21 27.615c1.327 0 1.826.155 2.422.751l3.668 3.667c.614.613.754 1.066.754 2.425v7.549c0 1.359-.141 1.813-.758 2.428L93.635 48.1c-.6.6-1.1.754-2.425.754H50.475V31.633l-8.9-4.018zM50.475 99.227V66.649H91.21c1.328 0 1.826.155 2.422.751l3.668 3.668c.614.612.754 1.065.754 2.424v18.892c0 1.359-.141 1.813-.758 2.428l-3.659 3.661a2.659 2.659 0 01-2.211.751zm95.213 62.69a3.768 3.768 0 11-3.778 3.769 3.761 3.761 0 013.778-3.769m0 8.269a4.5 4.5 0 10-4.512-4.5 4.475 4.475 0 004.512 4.5m.779-4.272a1.285 1.285 0 001.35-1.385c0-1.065-.824-1.34-1.739-1.34h-2.13v5.061h.731v-2.336h.939l1.3 2.336h.836zm-.527-1.992c.607 0 1.146.048 1.146.687 0 .688-.94.572-1.626.572h-.781v-1.259z"
						/>
					</svg>
				</div>
				<ul
					class="c-page__social-list reset-list d-flex align-items-center"
				>
					<li class="c-page__social-list-item">
						<a
							href="https://twitter.com/BUFootball"
							class="c-page__social-list-item-url"
							target="_blank"
						>
							<span
								aria-hidden="true"
								class="sidearm-icon sf-twitter"
							></span>
						</a>
					</li>
					<li class="c-page__social-list-item">
						<a
							href="https://www.facebook.com/BUFootball"
							class="c-page__social-list-item-url"
							target="_blank"
						>
							<span
								aria-hidden="true"
								class="sidearm-icon sf-facebook"
							></span>
						</a>
					</li>
					<li class="c-page__social-list-item">
						<a
							href="https://www.instagram.com/bufootball"
							class="c-page__social-list-item-url"
							target="_blank"
						>
							<span
								aria-hidden="true"
								class="sidearm-icon sf-instagram"
							></span>
						</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<MainFooter class="w-100" />
		</div>
	</div>
</template>

<script>
import Accordion from '@/components/page/Accordion';
import BasicButtons from '@/components/page/BasicButtons';
import BasicText from '@/components/page/BasicText';
import ImageGrid from '@/components/page/ImageGrid';
import MainFooter from '@/components/MainFooter';
import Photos from '@/components/page/Photos';
import PromoRotator from '@/components/page/PromoRotator';
import Videos from '@/components/page/Videos';

export default {
	name: 'Page',

	components: {
		Accordion,
		BasicButtons,
		BasicText,
		ImageGrid,
		MainFooter,
		Photos,
		PromoRotator,
		Videos,
	},

	data() {
		return {
			page: null,
		};
	},

	methods: {
		get_page_data(slug) {
			this.page = this.$root.pages.filter(page => page.slug === slug)[0];
		},
	},

	created() {
		document.body.classList = '';
		document.body.classList.add('common-page');

		this.$root.get_data().then(() => {
			this.get_page_data(this.$route.params.slug);
		});
	},

	watch: {
		$route(to) {
			this.get_page_data(to.params.slug);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/styles/app';

.c-page {
	&__inner {
		animation: fade-in-up 0.25s 0.5s ease forwards;
		opacity: 0;
		transform: translateY(#{$spacer * 2});
	}
	&__social {
		background: $color-primary;
		height: 174px;

		&-logo {
		}

		&-list {
			&-item {
				margin-left: $spacer;

				&-url {
					color: $color-white;
					font-size: 24px;
					text-decoration: none;
					transition: 0.25s;

					&:hover,
					&:focus {
						color: $color-secondary;
					}
				}
			}
		}
	}

	@media screen and (min-width: $breakpoint-medium) {
		&__social-logo {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
</style>
