<template>
	<div class="component c-promo-rotator">
		<swiper class="swiper relative" :options="swiper_options">
			<swiper-slide v-for="(item, index) in content" :key="index">
				<a class="d-block w-100" :href="item.link" target="_blank">
					<img :src="item.image" :alt="item.title" />
				</a>
			</swiper-slide>
			<button
				aria-label="Previous Slide"
				class="swiper-button-prev pa-1"
				slot="button-prev"
				type="button"
				v-if="content.length > 1"
			>
				<span
					aria-hidden="true"
					class="sidearm-icon sf-arrow-left-minimal"
				></span>
			</button>
			<button
				aria-label="Next Slide"
				class="swiper-button-next pa-1"
				slot="button-next"
				type="button"
				v-if="content.length > 1"
			>
				<span
					aria-hidden="true"
					class="sidearm-icon sf-arrow-right-minimal"
				></span>
			</button>
		</swiper>
	</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import '../../../node_modules/swiper/swiper.scss';

export default {
	name: 'PromoRotator',

	components: {
		Swiper,
		SwiperSlide,
	},

	props: {
		content: {
			required: true,
			type: Array,
		},
	},

	data() {
		return {
			swiper_options: {
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			},
		};
	},
};
</script>

<style lang="scss" scoped>
@import '@/styles/app';

.c-promo-rotator {
	&::after {
		content: '';
		background: $color-secondary;
		height: 6px;
		display: block;
		margin: $spacer * 2 auto 0;
		width: 100px;
	}
}

.swiper-button-prev,
.swiper-button-next {
	@include base-reset;
	background: rgba($color: $color-black, $alpha: 0.65);
	color: $color-white;
	font-size: 30px;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	transition: 0.25s;
	z-index: 2;

	&::after {
		content: '';
		background: $color-secondary;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		transform: scaleY(0);
		transform-origin: bottom;
		transition: 0.25s;
		width: 100%;
		z-index: -1;
	}

	&:hover,
	&:focus {
		cursor: pointer;

		&::after {
			transform: scaleY(1);
		}
	}
}

.swiper-button-next {
	left: auto;
	right: 0;
}
</style>
