<template>
	<ul
		class="component c-buttons reset-list d-flex align-items-center justify-content-center"
	>
		<li
			class="c-buttons__button ma-1"
			v-for="item in content"
			:key="item.id"
		>
			<a
				class="c-button__button-url text-transform-uppercase button"
				:href="item.url"
				target="_blank"
				>{{ item.title }}</a
			>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'BasicButtons',

	props: {
		content: {
			required: true,
			type: Array,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/styles/app';

.c-buttons {
	flex-wrap: wrap;
	margin: $spacer * -1 !important;
}
</style>
