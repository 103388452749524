<template>
	<div class="component c-accordion">
		<div class="c-accordion__title section-title text-primary mb-2">
			{{ content.title }}
		</div>
		<ul class="c-accordion__list reset-list">
			<li
				class="c-accordion__item mb-1"
				:id="`accordion-item-${index}`"
				v-for="(item, index) in content.items"
				:key="index"
			>
				<button
					:aria-label="`Toggle accordion #${index}`"
					class="c-accordion__item-button d-flex w-100"
					type="button"
					@click.prevent="toggle_accordion(`accordion-item-${index}`)"
				>
					<span
						class="c-accordion__item-button-title d-flex align-items-center flex-item-1 text-align-left px-2 h-100 relative"
						>{{ item.title }}</span
					>
					<span
						aria-hidden="true"
						class="sidearm-icon sf-arrow-down-minimal px-2 h-100 relative"
					></span>
				</button>
				<div class="c-accordion__item-details">
					<div class="pa-2">{{ item.details }}</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'Accordion',

	props: {
		content: {
			required: true,
			type: Object,
		},
	},

	methods: {
		toggle_accordion(id) {
			let item = document.getElementById(id);

			if (item && item.classList.contains('is-active')) {
				item.classList.remove('is-active');
			} else {
				item.classList.add('is-active');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/styles/app';

.c-accordion {
	&__item {
		background: #f3f3f3;

		&:last-child {
			margin-bottom: 0 !important;
		}

		&.is-active {
			.c-accordion__item {
				&-button {
					color: $color-white;
					cursor: pointer;

					&::after {
						transform: scaleY(1);
					}

					.sidearm-icon::before {
						transform: rotate(-180deg);
					}
				}
				&-details {
					max-height: 1500px;
				}
			}
		}

		&-button {
			@include base-reset;
			background: #f3f3f3;
			border-left: 3px solid $color-secondary;
			color: $color-black;
			height: 70px;
			position: relative;
			transition: 0.25s;

			&:hover,
			&:focus {
				color: $color-white;
				cursor: pointer;

				&::after {
					transform: scaleY(1);
				}
			}

			&::after {
				content: '';
				background: $color-primary;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				transform: scaleY(0);
				transform-origin: bottom;
				transition: 0.25s;
				width: 100%;
				z-index: 1;
			}

			&-title {
				font-size: 1.125rem;
				font-weight: 500;
				z-index: 2;
			}

			.sidearm-icon {
				border-left: 1px solid $color-white;
				flex: 0 0 auto;
				font-size: 24px;
				line-height: 70px;
				z-index: 2;

				&::before {
					display: inline-block;
					transition: transform 0.25s;
				}
			}
		}

		&-details {
			max-height: 0;
			overflow: hidden;
			transition: 0.25s;
		}
	}
}
</style>
