<template>
	<div class="component c-text text-align-center" v-html="content"></div>
</template>

<script>
export default {
	name: 'BasicText',

	props: {
		content: {
			required: true,
			type: String,
		},
	},
};
</script>

<style lang="scss" scoped>
.c-text {
	font-size: 1rem;
	line-height: 1.5;

	p {
		margin: 12px 0;

		&:first-of-type {
			margin-top: 0;
		}
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
</style>
