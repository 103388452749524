<template>
	<div class="component c-photos" v-if="photos && photos.length">
		<div class="c-photos__header text-primary section-title mb-4">
			Photos
		</div>
		<ul class="c-photos__list reset-list d-flex pb-1 x-large-pb-0">
			<li
				class="c-photos__item"
				v-for="(photo, index) in photos"
				:key="index"
			>
				<a
					:href="photo.url"
					class="c-photos__url d-block text-decoration-none text-black"
					target="_blank"
				>
					<div class="c-photos__media bg-black relative">
						<img
							:src="photo.image"
							:alt="photo.title"
							class="c-photos__image absolute text-white w-100"
						/>
					</div>
					<div class="c-photos__details pt-2">
						<div class="c-photos__title">{{ photo.title }}</div>
					</div>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'Photos',

	props: {
		slug: {
			required: true,
			type: String,
		},
	},

	data() {
		return {
			photos: null,
		};
	},

	methods: {
		get_photos() {
			axios
				.get(
					process.env.NODE_ENV === 'production'
						? `/services/tags.ashx/content?sport=0&section=Gallery&slug=${this.slug}`
						: `/dummy_data/photos.json`,
				)
				.then(response => {
					if (!response) return;

					this.photos = response.data.data;
				});
		},
	},

	created() {
		this.get_photos();
	},
};
</script>

<style lang="scss" scoped>
@import '@/styles/app';

.c-photos {
	&__list {
		margin: 0 $spacer * -0.5;
		overflow-x: auto;
	}
	&__item {
		flex: 0 0 60%;
		margin: 0 $spacer * 0.5;
	}
	&__url {
		&:hover,
		&:focus {
			.c-photos__image {
				transform: scale(1.1);
			}
		}
	}
	&__media {
		overflow: hidden;
		padding-bottom: 56.25%;
	}
	&__image {
		@include crispy_image;
		top: 0;
		left: 0;
		transition: 0.25s;
	}
	&__title {
		color: $color-black;
		font-family: $font-oswald;
		font-size: 1.25rem;
		font-weight: 700;
	}

	@media screen and (min-width: $breakpoint-medium) {
		&__item {
			flex: 0 0 40%;
		}
	}

	@media screen and (min-width: $breakpoint-x-large) {
		&__list {
			overflow: hidden;
		}
		&__item {
			flex: 0 1 33.333%;
		}
	}
}
</style>
