<template>
	<div class="component c-image-grid ">
		<ul class="c-image-grid__list d-flex reset-list pb-1 x-large-pb-0">
			<li
				class="c-image-grid__item"
				v-for="(item, index) in content"
				:key="index"
			>
				<a
					class="c-image-grid__item-url bg-black d-block text-decoration-none relative text-white"
					:href="item.url ? item.url : ''"
					target="_blank"
				>
					<img
						class="c-image-grid__item-img object-fit-positioning object-fit-cover"
						:src="item.image"
						:alt="item.title"
					/>
					<div class="c-image-grid__item-details absolute pa-2 w-100">
						<div
							class="c-image-grid__item-title text-transform-uppercase"
						>
							{{ item.title }}
						</div>
					</div>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'ImageGrid',

	props: {
		content: {
			required: true,
			type: Array,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/styles/app';

.c-image-grid {
	&::after {
		content: '';
		background: $color-secondary;
		height: 6px;
		display: block;
		margin: $spacer * 2 auto 0;
		width: 100px;
	}

	&__list {
		overflow-x: auto;
	}

	&__item {
		flex: 0 0 60%;
		margin-right: $spacer;

		&:last-child {
			margin-right: 0;
		}

		&-img {
			pointer-events: none;
			transition: 0.25s;
			z-index: 1;
		}

		&-details {
			pointer-events: none;
			bottom: 0;
			left: 0;
			z-index: 3;
		}

		&-title {
			@include responsive_text(375px, 1200px, 18px, 28px);
			font-family: $font-oswald;
			font-weight: 500;
			transition: 0.25s;
		}

		&-url {
			overflow: hidden;
			padding-bottom: 100%;
			z-index: 4;

			&::before {
				content: '';
				@include simple-gradient;
				height: 100%;
				pointer-events: none;
				position: absolute;
				bottom: 0;
				left: 0;
				transition: 0.25s;
				width: 100%;
				z-index: 2;
			}

			&:hover,
			&:focus {
				&::before {
					opacity: 0;
				}

				.c-image-grid__item-img {
					transform: scale(1.1);
				}

				.c-image-grid__item-title {
					transform: translateY(calc(100% + 48px));
				}
			}
		}
	}

	@media screen and (min-width: $breakpoint-medium) {
		&__item {
			flex: 0 0 45%;
		}
	}

	@media screen and (min-width: $breakpoint-x-large) {
		&__list {
			flex-wrap: wrap;
			margin: $spacer * -1 !important;
			overflow: hidden;
		}

		&__item {
			flex: 0 1 33.3333%;
			margin-right: 0;
			padding: $spacer;
		}
	}
}
</style>
